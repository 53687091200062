<template>
  <div class="updateSession">
    <top style="padding: 0.2rem 0"></top>
    <div class="">
      <div class="update_session_row_block">
        <div class="update_session_row_key">
          <span class="update_session_row_key_required">*</span> 角色绑定
        </div>
        <el-checkbox-group v-model="roles">
          <el-checkbox
            v-for="(item, index) in roleList"
            :label="item.role_name"
            :value="item.role_code"
            :key="index"
            @change="changeRole($event, item)"
          />
        </el-checkbox-group>
      </div>
      <div
        class="update_session_row"
        style="flex-direction: column"
        v-for="item in roles"
        :key="item"
      >
        <div style="width: 100%">
          <div class="update_session_row_block">
            <div
              class="update_session_row_key updateSession_key"
              style="color: #1e649e"
            >
              {{ item }}
            </div>
          </div>
          <div class="update_session_row_block">
            <div class="update_session_row_key">
              <span class="update_session_row_key_required">*</span>权益总次数
            </div>
            <div class="update_session_row_value">
              <!-- type="number"
                :max="3"
                :min="data[`doctor_${role[item]}`]" -->
              <el-input
                v-model.trim="data[role[item]]"
                placeholder="请输入权益次数"
                @input="(event) => handleInput(event, role[item])"
              ></el-input>
            </div>
          </div>
          <div class="update_session_row_block">
            <div class="update_session_row_key">
              <span class="update_session_row_key_required">*</span>剩余权益
            </div>
            <div class="update_session_row_value updateSession_padding">
              {{ data[role[item]] - data[`doctor_${role[item]}`] }}
            </div>
          </div>
          <div class="update_session_row_block">
            <div class="update_session_row_key">
              <span class="update_session_row_key_required">*</span>已使用权益
            </div>
            <div class="update_session_row_value updateSession_padding">
              {{ data[`doctor_${role[item]}`] || 0 }}
            </div>
          </div>
        </div>
      </div>

      <div class="update_session_buttongroup">
        <el-button type="primary" size="mini" class="commit" @click="doCommit"
          >确认</el-button
        >
      </div>
    </div>
  </div>
</template>
<script>
import top from "./top.vue";
top;
export default {
  name: "updateSession",
  components: {
    top,
  },
  data() {
    return {
      data: {
        cohosts_count: 0,
        speaker_count: 0,
        guest_count: 0,
      },
      roles: [],
      loading: false,
      roleList: [
        { role_code: "cohosts_count", role_name: "主席权益", count: 0 },
        { role_code: "speaker_count", role_name: "讲者权益", count: 0 },
        { role_code: "guest_count", role_name: "讨论权益", count: 0 },
      ],
      role: {
        主席权益: "cohosts_count",
        讲者权益: "speaker_count",
        讨论权益: "guest_count",
      },
      joinRoles: [],
    };
  },

  mounted() {
    document.getElementsByTagName("title")[0].innerHTML = "报名系统";
    this.loadData();
  },
  methods: {
    async loadData() {
      let url = this.$tools.getURL(this.$urls.hospital.detail, {
        id: this.$route.query.id,
      });
      await this.$axios.get(url).then((res) => {
        this.data = res.data;
        this.initData();
      });
    },
    initData() {
      this.roleList.map((v) => {
        v.count = this.data[v.role_code];
        if (v.count) {
          this.roles.push(v.role_name);
        }
      });
    },
    handleInput(e, item) {
      const regex = /^[0-9]+$/;

      if (!regex.test(this.data[item])) {
        this.data[item] = "";
      }
      if (e > 3) {
        this.data[item] = 3;
      }
    },

    changeRole(e, item) {
      if (e == false) {
        this.data[item.role_code] = 0;
      }
      if (item.count != 0) {
        this.roles.push(item.role_name);
        this.$tips.error({ text: "该角色还有权益次数,不可取消角色绑定" });
        this.data[item.role_code] = item.count;
      }
    },

    formatData() {
      for (let index = 0; index < this.roles.length; index++) {
        const element = this.roles[index];
        if (
          this.data[this.role[element]] <
          this.data[`doctor_${this.role[element]}`]
        ) {
          this.$tips.error({
            text: `修改${element}小于已使用的权益次数`,
          });
          return false;
        }
        if (this.data[this.role[element]] === "")
          return this.$tips.error({
            text: `请输入${element}的权益次数`,
          });
      }
      if (
        Number(this.data.cohosts_count) +
          Number(this.data.speaker_count) +
          Number(this.data.guest_count) >
        3
      ) {
        this.$tips.error({ text: "本期活动每位专家最多参与3次权益，请调整" });
        return false;
      }
      return true;
    },
    doCommit() {
      if (this.loading || !this.formatData()) {
        return;
      }
      this.loading = true;
      let currLoading = this.$tips.loading({});
      this.$axios
        .patch(this.$urls.hospital.update, { ...this.data })
        .then(() => {
          setTimeout(() => {
            this.loading = false;
            this.$tips.success({
              text: "修改成功",
            });
            currLoading.close();
            this.$router.back();
          }, 1000);
        })
        .catch(() => {
          setTimeout(() => {
            this.loading = false;
            currLoading.close();
          }, 1000);
        });
    },
  },
};
</script>
<style>
.updateSession {
  font-size: 0.625rem;
  /* position: absolute; */
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 200;
  /* background: rgba(0, 0, 0, 0.1); */
  background: #fff;
  display: flex;
  flex-direction: column;
  padding: 1.25rem;
}
.update_session_row {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
}

.updateSession .update_session_row_block {
  width: 100%;
  display: flex;
  align-items: center;
  min-height: 2rem;
}
.update_session_row_key {
  flex: 5rem 0 0;
  text-align: end;
  padding-right: 1rem;
}
.update_session_row_value {
  flex: 1 0 0;
}

.updateSession_padding {
  margin-left: 0.7rem;
}

.updateSession .el-checkbox__label {
  font-size: 0.625rem !important;
}
.update_session_row_key_required {
  color: red;
}
.update_session_buttongroup {
  height: 2.5rem;
  position: relative;
  /* z-index: 1000; */
  display: flex;
  align-items: center;
  justify-content: center;
}
.update_session_buttongroup .commit {
  background: #1e649e;
  border: none;
  width: 100%;
}
.updateSession .el-input__inner {
  width: 14rem;
  height: 1.25rem;
  font-size: 0.625rem !important;
}
.updateSession .el-checkbox__input.is-checked + .el-checkbox__label {
  color: #1e649e !important;
}
.updateSession .el-checkbox__input.is-checked .el-checkbox__inner {
  background-color: #1e649e !important;
  border-color: #1e649e !important;
}

.updateSession .el-input__inner:focus {
  border-color: #1e649e !important;
}
.updateSession .el-select .el-input.is-focus .el-input__wrapper,
.updateSession .el-input__wrapper:hover {
  box-shadow: 0 0 0 1px #1e649e inset !important;
}
</style>